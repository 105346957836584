// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.projects_img__zWAWO {
    margin: auto;
    display: block;
    width: 75%;
    height: 75%;
}`, "",{"version":3,"sources":["webpack://./src/styles/projects.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,cAAc;IACd,UAAU;IACV,WAAW;AACf","sourcesContent":[".img {\r\n    margin: auto;\r\n    display: block;\r\n    width: 75%;\r\n    height: 75%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"img": `projects_img__zWAWO`
};
export default ___CSS_LOADER_EXPORT___;
