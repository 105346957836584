// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.default_all__WFoz1 {
    font-family: "Comic Sans MS";
}

.default_allSpaced__Uv-32 * {
    margin: 10px;
}

.default_surfaceSpaced__jxqFX {
    margin: 10px;
}`, "",{"version":3,"sources":["webpack://./src/styles/default.module.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;AAChC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".all {\r\n    font-family: \"Comic Sans MS\";\r\n}\r\n\r\n.allSpaced * {\r\n    margin: 10px;\r\n}\r\n\r\n.surfaceSpaced {\r\n    margin: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"all": `default_all__WFoz1`,
	"allSpaced": `default_allSpaced__Uv-32`,
	"surfaceSpaced": `default_surfaceSpaced__jxqFX`
};
export default ___CSS_LOADER_EXPORT___;
