// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
    width: 25px;
    height: 25px;
}

li {
    display: inline;
}

label {
    display: block;
}

canvas {
    border: 1px solid white;
}`, "",{"version":3,"sources":["webpack://./src/styles/cellGrowthSim.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":["img {\r\n    width: 25px;\r\n    height: 25px;\r\n}\r\n\r\nli {\r\n    display: inline;\r\n}\r\n\r\nlabel {\r\n    display: block;\r\n}\r\n\r\ncanvas {\r\n    border: 1px solid white;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
